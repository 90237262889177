import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { defineComponent, ref } from 'vue';
import { loadPage } from '@/utils/publicMethods';
export default (Instance: any) => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'input',
      prop: 'businessNo',
      value: '',
      placeholder: '请输入商机编号',
    },
    {
      type: 'input',
      prop: 'name',
      value: '',
      placeholder: '请输入商机名称',
    },
    {
      type: 'input',
      prop: 'customerName',
      value: '',
      placeholder: '请输入客户',
    },
    {
      type: 'multiSelect',
      prop: 'status',
      value: [1, 2],
      options: 'business_status',
      width: 180,
      placeholder: '请选择状态',
    },
    {
      type: 'multiSelect',
      prop: 'orderStatus',
      value: [1, 3, 4, 5],
      options: 'query_order_status_value',
      width: 180,
      placeholder: '请选择订单状态',
    },
    {
      type: 'multiSelect',
      prop: 'productType',
      value: [],
      options: 'product_category',
      width: 180,
      placeholder: '请选择业务名称',
    },
    {
      type: 'signSelect',
      prop: 'isFunded',
      value: '',
      options: [
        {
          label: '是',
          value: true,
        },
        {
          label: '否',
          value: false,
        },
      ],
      placeholder: '请选择是否垫资',
    },
    {
      type: 'signSelect',
      prop: 'isDc',
      value: '',
      options: [
        {
          label: '是',
          value: true,
        },
        {
          label: '否',
          value: false,
        },
      ],
      placeholder: '请选择是否代采',
    },
    {
      type: 'number',
      prop: ['minAmount', 'maxAmount'],
      value: [],
      placeholder: ['总货值最小金额', '总货值最大金额'],
    },
    {
      type: 'multiSelect',
      prop: 'tradeCurrencies',
      value: [],
      options: 'trade_currency',
      width: 180,
      placeholder: '请选择交易币种',
    },
    {
      type: 'input',
      prop: 'consignee',
      value: '',
      placeholder: '请输入抬头',
    },
    {
      type: 'input',
      prop: 'contractNo',
      value: '',
      placeholder: '请输入客户合同号',
    },
    {
      type: 'input',
      prop: 'supplierContractNo',
      value: '',
      placeholder: '请输入供应商合同号',
    },
    {
      type: 'input',
      prop: 'invoiceNo',
      value: '',
      maxlength: 25,
      placeholder: '请输入发票号',
    },
    {
      type: 'input',
      prop: 'documentary',
      value: '',
      placeholder: '请输入单证跟单员',
    },
    {
      type: 'input',
      prop: 'fundmanager',
      value: '',
      placeholder: '请输入资管跟单员',
    },
    {
      type: 'input',
      prop: 'principal',
      value: '',
      placeholder: '请输入负责人',
    },
    {
      type: 'time',
      prop: ['startCreateTime', 'endCreateTime'],
      value: [],
      placeholder: ['创建开始时间', '创建结束时间'],
    },
  ];
  const columnList: CrmTableOptions[] = [
    {
      label: '商机编号',
      prop: 'businessNo',
      minWidth: 120,
    },
    {
      label: '商机名称',
      component: defineComponent({
        template: `
          <div class="crm-cell-link">
          {{row.name}}
          </div>
        `,
        props: ['row'],
        setup() {
          return {};
        },
      }),
      prop: 'name',
      minWidth: 140,
    },
    {
      label: '客户',
      component: defineComponent({
        template: `
          <div class="crm-cell-link" @click="clickFn(row)">
          {{row.customerName}}
          </div>
        `,
        props: ['row'],
        setup() {
          /** 编辑 */
          const clickFn = (row) => {
            console.log(' ----', row);
            Instance.setupState.rowData.value = row;
            let type = 0;
            if (row.isDeleted) {
              type = 3;
            } else {
              if (row.isInPool) {
                type = 2;
              } else {
                type = 1;
              }
            }
            loadPage('ContactDetails', {
              id: row.customerId,
              isRecycle: false,
              type, // 1客户 2客户池 3回收站
              backFrom: '/opportunity/opportinuityRecycleList',
            });
          };
          return { clickFn };
        },
      }),
      prop: 'customerName',
      minWidth: 140,
    },
    {
      label: '业务名称',
      prop: 'productTypeString',
      minWidth: 120,
    },
    {
      label: '是否垫资',
      prop: 'isFundedString',
      minWidth: 110,
    },
    {
      label: '是否代采',
      prop: 'isDcString',
      minWidth: 110,
    },
    {
      label: '总货值',
      prop: 'amountString',
      minWidth: 140,
    },
    {
      label: '交易币种',
      prop: 'tradeCurrencyName',
      minWidth: 110,
    },
    {
      label: '客户框架合同',
      prop: 'hasCustomerContract',
      minWidth: 140,
    },
    {
      label: '抬头',
      prop: 'consignee',
      minWidth: 140,
    },
    {
      label: '客户合同号',
      prop: 'contractNo',
      minWidth: 205,
    },
    {
      label: '供应商合同号',
      prop: 'supplierContractNo',
      minWidth: 170,
    },
    {
      label: '发票号',
      prop: 'invoiceNo',
      minWidth: 140,
    },
    {
      label: '订购单审核状态',
      prop: 'auditStatusDesc',
      minWidth: 180,
    },
    {
      label: '商机状态',
      prop: 'statusName',
      minWidth: 140,
    },
    {
      label: '订单状态',
      prop: 'orderStatusName',
      minWidth: 140,
    },
    {
      label: '单证跟单员',
      prop: 'documentary',
      minWidth: 160,
    },
    {
      label: '资管跟单员',
      prop: 'fundmanager',
      minWidth: 160,
    },
    {
      label: '负责人',
      prop: 'principal',
      minWidth: 140,
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      minWidth: 180,
    },
  ];
  return { filterOptions, columnList, tableRef };
};
